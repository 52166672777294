<template>
  <v-container fluid>
    {{ props }}
    <NotificationTemplate :key="key" :reference="props" />
  </v-container>
</template>

<script>
  import {
    defineComponent /* , inject, reactive, toRefs */,
    ref,
    watch,
  } from "vue";
  import NotificationTemplate from "@/components/Notification/NotificationTemplate.vue";
  //import { useActions, useGetters } from "vuex-composition-helpers";
  //import Api from "@/apis/Api";

  export default defineComponent({
    props: ["id", "type"],
    components: { NotificationTemplate },
    setup(props) {
      const key = ref(0);
      watch(
        () => props?.id,
        () => key.value++
      );
      return { props, key };
    },
  });
</script>


