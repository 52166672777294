<template>
  <v-container fluid> Hello </v-container>
</template>

<script>
  export default {
    props: ["reference"],
    setup(props) {
      console.log(props.reference);
    },
  };
</script>

<style>
</style>